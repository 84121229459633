@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html, body, #main {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

body {
    @apply bg-dark;
    @apply text-normal;
}

.input {
    @apply bg-input;
    @apply shadow-inner;
    @apply px-1.5 py-1;
    @apply text-sm;
    @apply rounded;
    @apply border;
    @apply border-gray-500;
    @apply w-full;
}

.selected-menu-option {
    box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.11);
}
